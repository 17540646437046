@import '@styles/mixins.style';

.ra-paper {
  position: relative;
  display: block;
  background-color: var(--ra-paper-color);
  border-radius: var(--ra-border-radius-xl);
  padding: var(--ra-paper-spacing-mobile);
  z-index: 5;
  width: auto;

  @include breakpoint(md) {
    padding: var(--ra-paper-spacing);
  }

  &--torn-edge {
    position: relative;
    filter: var(--ra-drop-shadow);
    border-radius: var(--ra-border-radius-xl) var(--ra-border-radius-xl) 0px 0px;

    &::after {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 24px;
      background-color: var(--ra-paper-color);
      mask: url('/assets/icons/torn-edge.svg');
      mask-position: top left;
      mask-size: contain;
      mask-repeat: repeat-x;
      transform: scaleX(-1);
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--elevation-super-light {
    box-shadow: var(--ra-elevation-super-light);
  }

  &--elevation-light {
    box-shadow: var(--ra-elevation-light);
  }

  &--elevation-heavy {
    box-shadow: var(--ra-elevation-heavy);
  }

  &--link {
    transition: transform 350ms ease, box-shadow 350ms ease;

    &:hover {
      text-decoration: none !important;
    }
  }

  &--animate {
    &-shadow {
      transition: box-shadow 200ms ease-in-out;
      &:hover {
        box-shadow: var(--ra-elevation-heavy);
      }
    }

    &-translateY {
      transition: transform 200ms ease-in-out;
      &:hover {
        transform: translateY(-12px);
      }
    }

    &-image-zoom {
      [data-animate='true'] {
        overflow: hidden;
        border-radius: var(--ra-border-radius-xl) var(--ra-border-radius-xl) 0px
          0px;
        img {
          transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
        }
      }
      &:hover {
        [data-animate='true'] {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
